<template>
  <div ref="pc_brand_list" class="overflow-hidden pc_brand_list">
    <div class="relative w-full pb-[300%] overflow-hidden">
      <ol
        ref="brand_carousel"
        data-vertical-carousel
        :data-carousel-reverse="reverse"
        :data-carousel-count="images.length"
        class="absolute top-0 left-0 h-full"
      >
        <li
          class="relative"
          v-for="(brand, brand_index) in image_list"
          :key="`brand_${brand_index}`"
        >
          <img :src="brand.image" :alt="brand.title" width="213" height="213" />
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BrandCarousel',
  props: {
    images: {
      type: Array,
    },
    reverse: {
      type: Boolean,
    },
  },
  data() {
    return {
      active_index: 0,
    };
  },
  watch: {
    images() {
      this.active_index = 0;
    },
  },
  computed: {
    image_list() {
      let list = JSON.parse(JSON.stringify(this.images));
      let first_list = [list[0], list[1], list[2]];
      list = list.concat(first_list);
      return list;
    },
  },
};
</script>
