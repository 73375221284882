<template>
  <div
    ref="MainDialog"
    class="fixed top-0 -left-full w-full right-0 bottom-0 z-40 flex items-center justify-center"
    data-dialog
  >
    <div
      data-dialog-content
      class="w-[400px] max-w-[90%] rounded-2xl bg-white p-10 relative z-10 text-center shadow-xl"
    >
      <p
        class="text-center font-bold text-primary_dark tracking-wider"
        v-html="main_dialog.msg"
      ></p>

      <button
        @click="CloseDialog"
        class="mt-10 mx-auto inline-block text-center underline text-primary_light font-clash-display font-semibold transition-colors duration-300 hover:text-secondary"
      >
        Close
      </button>
    </div>
    <div
      data-dialog-background
      class="absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-40"
    ></div>
  </div>
</template>

<script>
import { Dialog } from '@/gsap/dialog';
export default {
  name: 'MainDialog',
  data() {
    return {
      dialog_effect: null,
    };
  },
  methods: {
    CloseDialog() {
      this.$store.commit('SetMainDialog', {
        msg: '感謝您！我們將會盡快與您聯絡',
        action: false,
      });
    },
  },
  computed: {
    main_dialog() {
      return this.$store.state.main_dialog;
    },
    dialog_status() {
      return this.$store.state.main_dialog.status;
    },
  },
  watch: {
    dialog_status() {
      if (this.dialog_status) {
        this.dialog_effect.open(this.$refs.MainDialog);
      } else {
        this.dialog_effect.close(this.$refs.MainDialog);
      }
    },
  },
  mounted() {
    this.dialog_effect = new Dialog();
    if (this.dialog_status) {
      this.dialog_effect.open(this.$refs.MainDialog);
    }
  },
};
</script>
