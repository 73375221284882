<template>
  <header
    class="fixed top-0 left-0 right-0 z-20 flex items-center justify-between px-5 py-5 sm:px-10"
  >
    <div
      class="relative z-10 flex items-center justify-between w-full md:w-auto"
    >
      <router-link to="/">
        <img
          src="/img/logo_color.webp"
          width="112"
          height="41"
          alt="泳欣事業有限公司"
          class="w-28"
        />
      </router-link>
      <button
        @click="OpenMenu"
        class="block overflow-hidden menu_btn md:hidden"
      >
        <i class="block sm:w-12 w-10 h-[2px] bg-primary_light"></i>
        <i class="block sm:w-12 w-10 h-[2px] mt-1 bg-primary_light"></i>
      </button>
    </div>
    <ol class="relative z-10 items-center hidden -mr-5 md:flex">
      <li
        v-for="(item, item_index) in menu_list"
        :key="`menu_list_${item_index}`"
      >
        <router-link
          :to="item.link"
          class="px-5 font-medium transition-colors duration-300 font-clash-display text-primary_light hover:text-secondary"
        >
          {{ item.title }}
        </router-link>
      </li>
    </ol>

    <div
      class="absolute md:-top-[15%] -top-[50%] h-full left-0 right-0 z-0 bg-white opacity-90 blur-md transform scale-150"
    ></div>
  </header>
</template>

<script>
export default {
  name: 'MainHeader',
  methods: {
    OpenMenu() {
      this.$store.commit('SetMainMenu', true);
    },
  },
  computed: {
    menu_list() {
      return this.$store.state.menu_list;
    },
  },
};
</script>

<style scoped>
.menu_btn:hover i {
  transform-origin: 'left';
  animation: menu_btn_line 0.6s forwards;
}
.menu_btn:hover i:nth-of-type(1) {
  animation-delay: 0.2s;
}

@keyframes menu_btn_line {
  0% {
    transform-origin: right;
    transform: scaleX(1);
  }
  50% {
    transform-origin: right;
    transform: scaleX(0);
  }
  51% {
    transform-origin: left;
    transform: scaleX(0);
  }
  100% {
    transform-origin: left;
    transform: scaleX(1);
  }
}
</style>
