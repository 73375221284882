<template>
  <main class="">
    <BreadCrumb :path="breadcrumb" />
    <CarouselSection ref="CarouselSection" />
    <AboutSection ref="AboutSection" />
    <PcService class="hidden md:block" />
    <MbService ref="MbService" class="block md:hidden" />
    <ClientSection ref="ClientSection" />
    <ProjectListSection ref="ProjectListSection" />
    <BlogListSection ref="BlogListSection" />
  </main>
</template>

<script>
import CarouselSection from '@/components/home/Carousel.vue';
import AboutSection from '@/components/home/About.vue';
import PcService from '@/components/home/Service/PcService.vue';
import MbService from '@/components/home/Service/MbService.vue';
import ClientSection from '@/components/home/Client.vue';
import ProjectListSection from '@/components/home/ProjectList.vue';
import BlogListSection from '@/components/home/BlogList.vue';
import BreadCrumb from '@/components/BreadCrumb.vue';
import { GetMetaData } from '@/common/meta';
export default {
  name: 'HomeView',
  components: {
    CarouselSection,
    AboutSection,
    PcService,
    MbService,
    ClientSection,
    ProjectListSection,
    BlogListSection,
    BreadCrumb,
  },
  data() {
    return {
      section_scroll_effect: null,
      cover_box_scroll_effect: null,
      card_fadeup_effect: null,
      meta_data: null,
      breadcrumb: [
        {
          link: '/',
          title: '首頁',
        },
      ],
    };
  },
  mounted() {
    this.meta_data = GetMetaData('官方網站', '', '');
    this.$PageReady(this.meta_data.title);
  },
  created() {
    let bg_image_link = document.createElement('link');
    bg_image_link.as = 'image';
    bg_image_link.rel = 'preload';
    bg_image_link.href =
      'https://www.yongxin-design.com/img/bg_layerblur_lg.webp';
    document.head.appendChild(bg_image_link);
  },
  metaInfo() {
    return this.meta_data;
  },
};
</script>
