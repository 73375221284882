<template>
  <section ref="MainContent" class="relative z-[9] md:py-32 py-64">
    <div
      class="w-full max-w-screen-xl mx-auto xl:px-0 sm:px-10"
      data-section-header
    >
      <header class="px-5 mb-10 sm:px-0">
        <p class="relative block overflow-hidden leading-none md:w-2/3">
          <span
            data-title-line
            class="title_line opacity-10 absolute z-0 sm:top-2 top-1 left-0 right-0 h-[1px] bg-primary_light"
          ></span>
          <span
            data-title
            data-cursor-title
            class="block section_title text-primary_light"
            >{{ about_story.title }}</span
          >
          <span
            data-title-line
            class="title_line opacity-10 absolute z-0 sm:bottom-3 bottom-2 left-0 right-0 h-[1px] bg-primary_light"
          ></span>
        </p>
        <img
          data-floating
          data-min-x="-50"
          data-max-x="10"
          data-min-y="-55"
          data-max-y="-55"
          width="640"
          height="676"
          alt="泳欣事業有限公司"
          class="absolute top-0 z-[10] md:w-1/2 w-full pointer-events-none"
          src="/img/bg_layerblur_lg.webp"
        />
      </header>
      <div class="px-5 mt-10 sm:px-0">
        <div class="overflow-hidden">
          <h2 class="block overflow-hidden">
            <span
              data-sub-title
              class="block text-xl font-bold sub_title md:text-3xl sm:text-2xl text-primary_dark"
            >
              {{ about_story.strong_sub_title }}
              <span
                class="block mt-2 text-sm font-medium md:text-xl sm:text-base text-primary_dark sm:inline-block sm:mt-0"
                >{{ about_story.sub_title }}</span
              >
            </span>
          </h2>
        </div>
        <span
          data-content-line
          class="content_line w-4 h-[1px] bg-black my-5 block"
        ></span>
        <div data-content class="flex flex-wrap items-end content">
          <div
            class="w-full text-sm font-medium md:w-2/3 sm:text-base"
            v-html="about_story.content"
          ></div>
          <div class="w-full mt-10 md:w-1/3 md:mt-0">
            <MainButton
              class="border md:ml-auto border-primary_light border-opacity-80 text-primary_light hover:bg-primary_light hover:text-white"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import MainButton from '@/components/MainButton.vue';
export default {
  name: 'AboutSection',
  components: {
    MainButton,
  },
  data() {
    return {
      bg_tl: null,
    };
  },
  computed: {
    common_column_data() {
      return this.$store.state.common_column_data;
    },
    about_story() {
      return this.common_column_data.filter(
        (item) => item.column_name == 'about_story'
      )[0];
    },
  },
};
</script>
