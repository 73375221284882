import { get, post_form } from '@/common/request';

export function GetBlogData() {
  return get('blog/get');
}

export function GetBrandData() {
  return get('brand/get');
}

export function GetCarouselData() {
  return get('carousel/get');
}

export function GetCommonColumnData() {
  return get('common_column/get');
}

export function GetProjectData() {
  return get('project/get');
}

export function GetServiceData() {
  return get('service/get');
}

export function GetSeoMetaData() {
  return get('seo_meta/get');
}

export function sendContact(data) {
  let contact_form = new FormData();
  contact_form.append('form_data', JSON.stringify(data));
  return post_form('contact/send', contact_form);
}
