<template>
  <svg height="1em" width="1em" viewBox="0 0 14 14" fill="none">
    <path
      d="M13 7L1 7"
      stroke="CurrentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7 13L1 7L7 1"
      stroke="CurrentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'LeftArrow',
};
</script>
