<template>
  <div
    id="app"
    ref="app"
    class="relative flex flex-col min-h-screen overflow-x-hidden"
  >
    <div
      v-if="!first_loading"
      class="fixed top-0 bottom-0 left-0 right-0 z-30 bg-white"
    ></div>
    <MainHeader />
    <MainMenu v-if="data_load_finish" />
    <!-- <MainLanding /> -->
    <MainLoading @refresh-gsap="RefreshAllEffect" />
    <MainDialog />
    <router-view
      v-if="data_load_finish"
      class="relative z-10 flex-1"
      @refresh-cursor="RefreshCursor"
      @add-card="AddCardFadeUp"
    />
    <MainBgLine ref="MainBgLine" />
    <MainBg />
    <MainFooter v-if="data_load_finish" />

    <span
      id="cursor_circle"
      ref="cursor_item"
      class="fixed top-0 left-0 z-10 block pointer-events-none"
    >
      <span
        class="flex items-center justify-center font-semibold leading-none text-white bg-opacity-50 rounded-full main_content font-clash-display bg-primary_light"
      >
        <span class="read_more">Read<br />More</span>
        <span class="link">GO</span>
      </span>
    </span>
  </div>
</template>

<style src="@/assets/css/app.css"></style>

<script>
import MainBgLine from '@/components/MainBgLine.vue';
import MainBg from '@/components/MainBg.vue';
import MainFooter from '@/components/MainFooter.vue';
import MainHeader from '@/components/MainHeader.vue';
import MainMenu from '@/components/MainMenu.vue';
import MainDialog from '@/components/MainDialog.vue';
import MainLoading from '@/components/MainLoading.vue';
// import MainLanding from '@/components/Landing.vue';
import { ScrollTrigger } from '@/gsap/gsap_loader';
import { CursorMove } from '@/gsap/cursor';
import { VerticalGrow } from '@/gsap/scroll/vertical_grow';
import { Floating } from '@/gsap/floating';
import { FullScreenScroll } from '@/gsap/scroll/fullscreen';
import { SectionScroll } from '@/gsap/scroll/section_scroll';
import { CoverBoxScroll } from '@/gsap/scroll/cover_box_scroll';
import { CardFadeUp } from '@/gsap/scroll/card_fadeup';
import { BgParallaxScroll } from '@/gsap/scroll/bg_parallax';
import { LoadImage } from './gsap/image_loaded';

export default {
  name: 'App',
  components: {
    MainBgLine,
    MainBg,
    MainFooter,
    MainHeader,
    MainMenu,
    MainDialog,
    MainLoading,
    // MainLanding,
  },
  data() {
    return {
      cursor_tracking: null,
      vertical_grow_effect: null,
      floating_effect: null,
      fullscreen_scroll_effect: null,
      section_scroll_effect: null,
      cover_box_scroll_effect: null,
      card_fadeup_effect: null,
      bg_prarllax_effect: null,
      first_loading: false,
    };
  },
  methods: {
    RefreshCursor() {
      this.cursor_tracking.refresh();
    },
    RefreshAllEffect() {
      ScrollTrigger.refresh();
      this.cursor_tracking.refresh();
      this.vertical_grow_effect.refresh();
      this.floating_effect.refresh();
      this.fullscreen_scroll_effect.refresh();
      this.section_scroll_effect.refresh();
      this.cover_box_scroll_effect.refresh();
      this.card_fadeup_effect.refresh();
      this.bg_prarllax_effect.refresh();
    },
    CheckPageData() {
      this.blog_data == null ? this.$store.dispatch('GetBlogData') : '';
      this.brand_data == null ? this.$store.dispatch('GetBrandData') : '';
      this.carousel_data == null ? this.$store.dispatch('GetCarouselData') : '';
      this.common_column_data == null
        ? this.$store.dispatch('GetCommonColumnData')
        : '';
      this.project_data == null ? this.$store.dispatch('GetProjectData') : '';
      this.service_data == null ? this.$store.dispatch('GetServiceData') : '';
      this.seo_meta_data == null ? this.$store.dispatch('GetSeoMetaData') : '';
    },
    AddCardFadeUp(index) {
      this.card_fadeup_effect.add(index);
    },
  },
  computed: {
    page_image_loaded() {
      return this.$store.state.page_image_loaded;
    },
    blog_data() {
      return this.$store.state.blog_data;
    },
    brand_data() {
      return this.$store.state.brand_data;
    },
    carousel_data() {
      return this.$store.state.carousel_data;
    },
    common_column_data() {
      return this.$store.state.common_column_data;
    },
    project_data() {
      return this.$store.state.project_data;
    },
    service_data() {
      return this.$store.state.service_data;
    },
    seo_meta_data() {
      return this.$store.state.seo_meta_data;
    },
    data_load_finish() {
      if (
        this.blog_data != null &&
        this.brand_data != null &&
        this.carousel_data != null &&
        this.common_column_data != null &&
        this.project_data != null &&
        this.service_data != null &&
        this.seo_meta_data != null
      ) {
        return true;
      } else {
        return false;
      }
    },
    main_loading() {
      return this.$store.state.main_loading;
    },
  },
  watch: {
    page_image_loaded() {
      if (this.page_image_loaded) {
        setTimeout(() => {
          console.log('ScrollTrigger refresh');
          ScrollTrigger.refresh();
          // this.cursor_tracking.refresh();
          // this.vertical_grow_effect.refresh();
          // this.floating_effect.refresh();
          // this.fullscreen_scroll_effect.refresh();
          // this.section_scroll_effect.refresh();
          // this.cover_box_scroll_effect.refresh();
          // this.card_fadeup_effect.refresh();
          // this.bg_prarllax_effect.refresh();
        }, 200);
      }
    },
    data_load_finish() {
      this.data_load_finish
        ? this.$nextTick(() => {
            LoadImage();
          })
        : '';
    },
    $route() {
      console.log('route change');
      this.$store.commit('SetImageLoaded', false);
      this.$nextTick(() => {
        this.cursor_tracking.refresh();
        this.vertical_grow_effect.refresh();
        this.floating_effect.refresh();
        this.fullscreen_scroll_effect.refresh();
        this.section_scroll_effect.refresh();
        this.cover_box_scroll_effect.refresh();
        this.card_fadeup_effect.refresh();
        this.bg_prarllax_effect.refresh();
      });
    },
    main_loading() {
      if (this.main_loading == 0) {
        setTimeout(() => {
          this.first_loading = true;
        }, 1000);
      }
    },
  },
  mounted() {
    this.$refs.MainBgLine.InitGsap();
    this.cursor_tracking = new CursorMove();
    this.cursor_tracking.addMoreMark();
    this.cursor_tracking.addTitleMark();
    this.cursor_tracking.addLinkMark();
    this.vertical_grow_effect = new VerticalGrow();
    this.floating_effect = new Floating();
    this.fullscreen_scroll_effect = new FullScreenScroll();
    this.section_scroll_effect = new SectionScroll();
    this.cover_box_scroll_effect = new CoverBoxScroll();
    this.card_fadeup_effect = new CardFadeUp();
    this.bg_prarllax_effect = new BgParallaxScroll();
  },
  created() {
    this.data_load_finish
      ? this.$nextTick(() => {
          LoadImage();
        })
      : '';
    this.CheckPageData();
  },
};
</script>

<style>
#cursor_circle .main_content {
  width: 20px;
  height: 20px;
  transition: all 0.3s;
}
#cursor_circle.more_hover .main_content {
  width: 80px;
  height: 80px;
}
#cursor_circle.link_hover .main_content {
  width: 60px;
  height: 60px;
}
#cursor_circle .read_more {
  position: absolute;
  transform: scale(0);
  transition: all 0.3s;
}
#cursor_circle.more_hover .read_more {
  position: relative;
  transform: scale(1);
}
#cursor_circle .link {
  position: absolute;
  transform: scale(0);
  transition: all 0.3s;
  font-size: 20px;
}
#cursor_circle.link_hover .link {
  position: relative;
  transform: scale(1);
}
#cursor_circle.title_hover .main_content {
  width: 60px;
  height: 60px;
}
</style>
