<template>
  <section id="ProjectList" ref="MainContent" class="relative z-10 mt-32">
    <div class="w-full max-w-screen-xl px-5 mx-auto xl:px-0 sm:px-10">
      <header class="mb-10" data-section-header>
        <p class="relative block w-full overflow-hidden">
          <span
            data-title-line
            class="title_line opacity-10 absolute z-0 sm:top-2 top-1 left-0 right-0 h-[.5px] bg-primary_light"
          ></span>
          <span
            data-title
            data-cursor-title
            class="relative z-10 block leading-none section_title text-secondary"
            >Project List</span
          >
          <span
            data-title-line
            class="title_line opacity-10 absolute z-0 sm:bottom-3 bottom-2 left-0 right-0 h-[1px] bg-primary_light"
          ></span>
        </p>
        <span
          data-content-line
          class="content_line w-4 h-[1px] bg-secondary mt-4 mb-2 block"
        ></span>
        <div data-content class="flex flex-wrap items-start content">
          <h2 class="w-full text-sm text-secondary md:w-2/3 sm:text-base">
            精選設計、網站作品
          </h2>
          <div class="w-full mt-10 text-right md:w-1/3 md:mt-0">
            <MainButton
              url="/Case_Overview/all"
              text="View All"
              class="inline-flex border md:ml-auto border-secondary border-opacity-80 text-secondary hover:bg-secondary hover:text-white"
            />
          </div>
        </div>
      </header>
      <div class="flex flex-wrap -mx-10">
        <ProjectCard
          data-card-fadeup
          v-for="(item, item_index) in project_list"
          :project_data="item"
          :key="`card_${item_index}`"
          class="project_card"
          :class="item_index % 2 == 0 ? 'md:mt-10' : ''"
        />
      </div>
    </div>
  </section>
</template>

<script>
import MainButton from '@/components/MainButton.vue';
import ProjectCard from '@/components/home/ProjectList/Card.vue';
export default {
  name: 'ProjectList',
  components: {
    MainButton,
    ProjectCard,
  },
  data() {
    return {
      // project_list: [
      //   {
      //     title: 'MAXXIS S98',
      //     content:
      //       'MAXXIS的出現，必將帶領人類走向更高的巔峰。MAXXIS，到底應該如何實現。謹慎地來說，我們必須考慮到所有可能。一般來說，經過上述討論，既然如此，富蘭克林講過一段耐人尋思的話，從事一項事情，先要決定志向，志向決定之後就要全力以赴毫不猶豫地去實行。他會這麼說是有理由的。',
      //     cover_image: '/img/projects/Project_list_card_img_1.png',
      //     tags: ['RWD響應式網頁設計', 'UI設計', '後台建置'],
      //   },
      //   {
      //     title: 'DEGO VESPA',
      //     content:
      //       '車爾尼雪夫斯基說過一句很有意思的話，生命，如果跟時代的崇高的責任聯繫在一起，你就會感到永垂不朽。帶著這句話，我們還要更加慎重的審視這個問題。深入的探討DEGO VESPA，是釐清一切的關鍵。俗話說的好，掌握思考過程，也就掌握了DEGO VESPA。',
      //     cover_image: '/img/projects/Project_list_card_img_2.png',
      //     tags: ['CIS設計'],
      //   },
      //   {
      //     title: '盛億水電工程行',
      //     content:
      //       '     莎士比亞在不經意間這樣說過，意志命運往往背道而馳，決心到最後會全部推倒。這是撼動人心的。李光庭講過，吃飯先喝湯，不用請藥方。帶著這句話，我們還要更加慎重的審視這個問題。帶著這些問題，我們一起來審視盛億水電工程行。車爾尼雪夫斯基說過一句富有哲理的話，應該堅信，思想和內容不是通過沒頭沒腦的感傷，而是通過思考而得到的。這不禁令我重新仔細的思考。',
      //     cover_image: '/img/projects/Project_list_card_img_3.png',
      //     tags: ['CIS設計', '名片設計'],
      //   },
      //   {
      //     title: 'MAXXIS S98',
      //     content:
      //       'MAXXIS的出現，必將帶領人類走向更高的巔峰。MAXXIS，到底應該如何實現。謹慎地來說，我們必須考慮到所有可能。一般來說，經過上述討論，既然如此，富蘭克林講過一段耐人尋思的話，從事一項事情，先要決定志向，志向決定之後就要全力以赴毫不猶豫地去實行。他會這麼說是有理由的。',
      //     cover_image: '/img/projects/Project_list_card_img_1.png',
      //     tags: ['RWD響應式網頁設計', 'UI設計', '後台建置'],
      //   },
      //   {
      //     title: 'DEGO VESPA',
      //     content:
      //       '車爾尼雪夫斯基說過一句很有意思的話，生命，如果跟時代的崇高的責任聯繫在一起，你就會感到永垂不朽。帶著這句話，我們還要更加慎重的審視這個問題。深入的探討DEGO VESPA，是釐清一切的關鍵。俗話說的好，掌握思考過程，也就掌握了DEGO VESPA。',
      //     cover_image: '/img/projects/Project_list_card_img_2.png',
      //     tags: ['CIS設計'],
      //   },
      //   {
      //     title: '盛億水電工程行',
      //     content:
      //       '     莎士比亞在不經意間這樣說過，意志命運往往背道而馳，決心到最後會全部推倒。這是撼動人心的。李光庭講過，吃飯先喝湯，不用請藥方。帶著這句話，我們還要更加慎重的審視這個問題。帶著這些問題，我們一起來審視盛億水電工程行。車爾尼雪夫斯基說過一句富有哲理的話，應該堅信，思想和內容不是通過沒頭沒腦的感傷，而是通過思考而得到的。這不禁令我重新仔細的思考。',
      //     cover_image: '/img/projects/Project_list_card_img_3.png',
      //     tags: ['CIS設計', '名片設計'],
      //   },
      // ],
    };
  },
  computed: {
    project_list() {
      return this.$store.state.project_data.slice(0, 6);
    },
  },
};
</script>

<style scoped>
#ProjectList {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(6, 147, 78, 0.4) 22%
  );
}
</style>
