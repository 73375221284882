import { gsap } from '@/gsap/gsap_loader';
var menu_tl = null;

export function OpenMenu(el) {
  menu_tl != null ? menu_tl.kill() : '';
  let bg_line = el.querySelector('.bg_line');
  let title_line = el.querySelectorAll('.title_line');
  let close_btn = el.querySelector('.close_btn');
  let link_btn = el.querySelectorAll('.link_btn');
  let info = el.querySelectorAll('.info');

  menu_tl = gsap.timeline({
    defaults: {
      duration: 0.6,
      ease: 'power4.inOut',
    },
  });
  menu_tl
    .set(el, {
      x: '-100%',
    })
    .fromTo(
      el,
      {
        opacity: 0,
      },
      {
        opacity: 1,
      }
    )
    .fromTo(
      bg_line,
      {
        scaleY: 0,
      },
      {
        scaleY: 1,
      },
      'line'
    )
    .fromTo(
      title_line,
      {
        transformOrigin: 'left',
        scaleX: 0,
      },
      {
        transformOrigin: 'left',
        scaleX: 1,
      },
      'line'
    )
    .fromTo(
      close_btn,
      {
        x: '-10%',
        opacity: 0,
      },
      {
        x: '0%',
        opacity: 1,
      }
    )
    .fromTo(
      link_btn,
      {
        opacity: 0,
        y: '10%',
      },
      {
        opacity: 1,
        y: '0%',
      },
      'list'
    )
    .fromTo(
      info,
      {
        opacity: 0,
        y: '10%',
      },
      {
        opacity: 1,
        y: '0%',
      },
      'list'
    );
}

export function CloseMenu(el) {
  let bg_line = el.querySelector('.bg_line');
  let title_line = el.querySelectorAll('.title_line');
  let close_btn = el.querySelector('.close_btn');
  let link_btn = el.querySelectorAll('.link_btn');
  let info = el.querySelectorAll('.info');
  menu_tl != null ? menu_tl.kill() : '';
  menu_tl = gsap.timeline({
    defaults: {
      duration: 0.6,
      ease: 'power4.inOut',
    },
  });
  menu_tl
    .to(
      info,
      {
        opacity: 0,
        y: '10%',
      },
      'title'
    )
    .to(
      link_btn,
      {
        opacity: 0,
        y: '10%',
      },
      'title'
    )
    .to(
      close_btn,
      {
        opacity: 0,
        x: '-10%',
      },
      'title'
    )
    .to(
      title_line,
      {
        scaleX: 0,
      },
      'line'
    )
    .to(
      bg_line,
      {
        scaleY: 0,
      },
      'line'
    )
    .to(el, {
      opacity: 0,
    })
    .set(el, {
      x: '0',
    });
}

export function ResetGsap(el) {
  let bg_line = el.querySelector('.bg_line');
  let title_line = el.querySelectorAll('.title_line');
  let close_btn = el.querySelector('.close_btn');
  let link_btn = el.querySelectorAll('.link_btn');
  let info = el.querySelectorAll('.info');
  gsap
    .timeline()
    .set(bg_line, {
      scaleY: 0,
    })
    .set(el, {
      opacity: 0,
    })
    .set(title_line, {
      scaleX: 0,
    })
    .set(close_btn, {
      opacity: 0,
    })
    .set(link_btn, {
      opacity: 0,
    })
    .set(info, {
      opacity: 0,
    });
}
