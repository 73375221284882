<template>
  <section
    ref="MainContent"
    class="relative z-10 w-full max-w-screen-xl px-5 py-32 mx-auto xl:px-0 sm:px-10"
    data-section-header
  >
    <header class="mb-10">
      <h2 class="relative block overflow-hidden">
        <span
          data-title-line
          class="title_line opacity-10 absolute sm:top-2 top-1 left-0 right-0 h-[.5px] bg-primary_light"
        ></span>
        <span
          data-title
          data-cursor-title
          class="block leading-none section_title text-primary_light"
        >
          Client
        </span>
        <span
          data-title-line
          class="title_line opacity-10 absolute sm:bottom-3 bottom-2 left-0 right-0 h-[1px] bg-primary_light"
        ></span>
      </h2>
      <span
        data-content-line
        class="content_line w-4 h-[1px] bg-primary_light mt-4 mb-2 block"
      ></span>
      <div class="flex flex-wrap items-start overflow-hidden content">
        <div
          data-sub-title
          class="w-full text-sm text-primary_light md:w-2/3 sm:text-base"
          v-html="client_section.content"
        ></div>
        <!-- <div class="w-full mt-10 md:w-1/3 md:mt-0">
          <MainButton
            text="View All"
            class="border md:ml-auto border-primary_light border-opacity-80 text-primary_light hover:bg-primary_light hover:text-white"
          />
        </div> -->
      </div>
      <img
        data-floating
        data-min-x="-50"
        data-max-x="50"
        data-min-y="-70"
        data-max-y="0"
        width="640"
        height="676"
        alt="泳欣事業有限公司"
        class="absolute top-0 right-0 z-[10] md:w-[60%] w-full pointer-events-none"
        src="/img/bg_layerblur_lg.webp"
      />
    </header>
    <div data-content class="flex items-stretch content">
      <div
        class="relative"
        :style="`width:${100 / active_brand_list.length}%`"
        v-for="(item, item_index) in active_brand_list"
        :key="`brand_carousel_${item_index}`"
      >
        <div
          v-for="index in 3"
          :key="`plus_${index}`"
          class="absolute top-0 left-0 w-full h-1/3"
          :class="index == 1 ? 'top-0' : index == 2 ? 'top-1/3' : 'top-2/3'"
        >
          <PlusIcon pos="top_left" />
          <PlusIcon v-if="index == 3" pos="bottom_left" />
          <PlusIcon
            v-if="item_index == active_brand_list.length - 1"
            pos="top_right"
          />
          <PlusIcon
            v-if="item_index == active_brand_list.length - 1"
            pos="top_right"
          />
          <PlusIcon
            v-if="item_index == active_brand_list.length - 1 && index == 3"
            pos="bottom_right"
          />
        </div>

        <BrandCarousel :reverse="item_index % 2 == 0" :images="item" />
      </div>
    </div>
  </section>
</template>

<script>
import BrandCarousel from '@/components/home/Client/BrandCarousel.vue';
import { VerticalCarousel } from '@/gsap/carousel/vertical.js';
import PlusIcon from '@/components/home/Client/PlusIcon.vue';
import { LoadImage } from '@/gsap/image_loaded';
export default {
  name: 'ClientSection',
  components: {
    // MainButton,
    BrandCarousel,
    PlusIcon,
  },
  data() {
    return {
      window_width: window.innerWidth,
      bg_tl: null,
      vertical_carousel: null,
    };
  },
  methods: {
    GetBrandList(count) {
      let brand_carousel_array = [];
      let tmp_count = 0;
      for (let i = 0; i < count; i++) {
        brand_carousel_array.push([]);
      }

      this.brand_list.forEach((item) => {
        brand_carousel_array[tmp_count].push(item);
        tmp_count == count - 1 ? (tmp_count = 0) : (tmp_count += 1);
      });

      // brand_carousel_array.forEach((item, item_index) => {
      //   brand_carousel_array[item_index] = this.SetBrandItem(
      //     brand_carousel_array[item_index],
      //     item_index,
      //     count
      //   );

      //   if (brand_carousel_array[item_index].length < 6) {
      //     let brand_count = 0;
      //     let max_count = brand_carousel_array[item_index].length - 1;
      //     do {
      //       brand_carousel_array[item_index].push(
      //         brand_carousel_array[item_index][brand_count]
      //       );
      //       brand_count == max_count ? (brand_count = 0) : (brand_count += 1);
      //     } while (brand_carousel_array[item_index].length < 6);
      //   }
      // });
      return brand_carousel_array;
    },
    SetBrandItem(array, index, count) {
      let start_index = index;
      do {
        if (array.length < 6) {
          array.push(this.brand_list[start_index]);
          start_index += count;
        } else {
          start_index = 9999;
        }
      } while (start_index < this.brand_list.length);
      return array;
    },
  },
  watch: {
    active_brand_list() {
      this.$nextTick(() => {
        this.vertical_carousel.refresh();
        this.$store.commit('SetImageLoaded', false);
        LoadImage();
      });
    },
  },
  computed: {
    active_brand_list() {
      if (this.window_width <= 640) {
        return this.sm_brand_list;
      } else if (this.window_width <= 768) {
        return this.mb_brand_list;
      } else {
        return this.pc_brand_list;
      }
    },
    brand_list() {
      return this.$store.state.brand_data;
    },
    common_column_data() {
      return this.$store.state.common_column_data;
    },
    client_section() {
      return this.common_column_data.filter(
        (item) => item.column_name == 'client_section'
      )[0];
    },
    pc_brand_list() {
      return this.GetBrandList(6);
    },
    mb_brand_list() {
      return this.GetBrandList(4);
    },
    sm_brand_list() {
      return this.GetBrandList(3);
    },
  },
  mounted() {
    this.vertical_carousel = new VerticalCarousel();
    this.vertical_carousel.setup();
    this.GetBrandList();
    window.addEventListener('resize', () => {
      this.window_width = window.innerWidth;
    });
  },
};
</script>
