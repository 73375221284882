<template>
  <section class="relative z-[11] h-screen">
    <!-- pb-[56.25%] -->
    <ol class="absolute top-0 left-0 z-10 flex items-stretch w-full h-full">
      <li
        @mouseenter="active_service = item_index"
        class="flex items-center justify-center w-1/3 h-full px-8 lg:px-16"
        :class="item_index == 1 ? 'border-l border-r border-zinc-700' : ''"
        v-for="(item, item_index) in service_list"
        :key="`service_${item_index}`"
      >
        <div class="relative text-white">
          <div
            class="relative z-10 transition-transform duration-300 transform"
            :class="active_service == item_index ? '' : 'translate-y-[4.5rem]'"
          >
            <img
              :src="item.image"
              width="96"
              height="96"
              :alt="item.sub_title"
              class="w-24 mx-auto"
            />
            <h4 class="mt-10 text-xl text-center">{{ item.sub_title }}</h4>
            <div class="mt-5 text-sm" v-html="item.description"></div>
            <ol class="mt-5 -mx-1">
              <li
                class="inline-block px-2 py-1 mx-1 mb-1 text-xs border border-white rounded-full font-clash-display"
                v-for="(tag, tag_index) in item.tags"
                :key="`tag_${item_index}_${tag_index}`"
              >
                {{ tag }}
              </li>
            </ol>
          </div>
          <router-link
            :aria-label="item.sub_title"
            :to="item.link"
            class="relative z-0 flex items-center justify-center w-16 h-16 mx-auto mt-10 transition-all duration-300 transform bg-white rounded-full hover:bg-primary_light text-primary_light hover:text-white"
            :class="
              active_service == item_index
                ? ''
                : '-translate-y-[4.5rem] opacity-0'
            "
          >
            <RighArrow class="text-xs" />
          </router-link>
        </div>
      </li>
    </ol>
    <div
      class="absolute top-0 bottom-0 left-0 right-0 z-0 bg-center bg-no-repeat bg-cover"
      :style="`background-image:url('${service_list[active_service].background_image}')`"
    >
      <!-- <img
        :src="service_list[active_service].background_image"
        class="object-cover w-full h-full"
      /> -->
    </div>
  </section>
</template>

<script>
import RighArrow from '@/components/svg/RightArrow.vue';
export default {
  name: 'ServiceSection',
  components: {
    RighArrow,
  },
  data() {
    return {
      active_service: 0,
    };
  },
  computed: {
    service_list() {
      return this.$store.state.service_data;
    },
  },
};
</script>
