<template>
  <svg
    height="1em"
    width="1em"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.9836 9C18.9836 4.05 14.9336 0 9.98361 0C5.03361 0 0.983612 4.05 0.983612 9C0.983612 13.5 4.24611 17.2125 8.52111 17.8875V11.5875H6.27111V9H8.52111V6.975C8.52111 4.725 9.87111 3.4875 11.8961 3.4875C12.9086 3.4875 13.9211 3.7125 13.9211 3.7125V5.9625H12.7961C11.6711 5.9625 11.3336 6.6375 11.3336 7.3125V9H13.8086L13.3586 11.5875H11.2211V18C15.7211 17.325 18.9836 13.5 18.9836 9Z"
      fill="CurrentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'FacebookIcon',
};
</script>
