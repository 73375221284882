<template>
  <div class="plus_icon" :class="pos">
    <i class="absolute w-2 h-[1px] bg-primary_light"></i>
    <i class="absolute w-[1px] h-2 bg-primary_light"></i>
  </div>
</template>

<script>
export default {
  name: 'PlusIcon',
  props: {
    pos: {
      type: String,
      default: 'top_left',
    },
  },
};
</script>

<style scoped>
.plus_icon.top_left i:nth-of-type(1) {
  top: 0;
  left: 0;
  transform: translateX(-3.5px);
}
.plus_icon.top_left i:nth-of-type(2) {
  top: 0;
  left: 0;
  transform: translateY(-3.5px);
}
.plus_icon.top_right i:nth-of-type(1) {
  top: 0;
  right: 0;
  transform: translateX(3.5px);
}
.plus_icon.top_right i:nth-of-type(2) {
  top: 0;
  right: 0;
  transform: translateY(-3.5px);
}
.plus_icon.bottom_left i:nth-of-type(1) {
  bottom: 0;
  left: 0;
  transform: translateX(-3.5px);
}
.plus_icon.bottom_left i:nth-of-type(2) {
  bottom: 0;
  left: 0;
  transform: translateY(3.5px);
}
.plus_icon.bottom_right i:nth-of-type(1) {
  bottom: 0;
  right: 0;
  transform: translateX(3.5px);
}
.plus_icon.bottom_right i:nth-of-type(2) {
  bottom: 0;
  right: 0;
  transform: translateY(3.5px);
}
</style>
