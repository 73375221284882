import Vue from 'vue';
import Vuex from 'vuex';
import {
  GetBlogData,
  GetBrandData,
  GetCarouselData,
  GetCommonColumnData,
  GetProjectData,
  GetServiceData,
  GetSeoMetaData,
} from '@/api/page.js';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    page_image_loaded: false,
    main_menu: false,
    main_loading: 0,
    landing: false,
    main_dialog: {
      msg: '',
      status: false,
    },
    image_dialog: {
      status: false,
      image: '',
    },
    blog_category: [
      {
        title: '粉專行銷',
        color: '#CC4444',
      },
      {
        title: '網站設計',
        color: '#5B5C24',
      },
      {
        title: '品牌設計',
        color: '#003D63',
      },
      {
        title: '其他',
        color: '#333333',
      },
    ],
    menu_list: [
      {
        title: 'About Us',
        link: '/About',
      },
      {
        title: 'Service',
        link: '/Service',
      },
      {
        title: 'Project List',
        link: '/Case_Overview/all',
      },
      {
        title: 'Blog',
        link: '/Blog',
      },
      {
        title: 'Contact Us',
        link: '/Contact',
      },
    ],
    blog_data: null,
    brand_data: null,
    carousel_data: null,
    common_column_data: null,
    project_data: null,
    service_data: null,
    seo_meta_data: null,
  },
  getters: {},
  mutations: {
    SetPageData(state, { key, val }) {
      state[key] = val;
    },
    SetMainMenu(state, action) {
      state.main_menu = action;
    },
    SetLanding(state, action) {
      state.landing = action;
    },
    SetImageLoaded(state, action) {
      state.page_image_loaded = action;
    },
    SetLoading(state, action) {
      state.main_loading += action;
    },
    SetMainDialog(state, { msg, action }) {
      state.main_dialog.msg = msg;
      state.main_dialog.status = action;
    },
    SetImageModal(state, { status, image }) {
      state.image_dialog.status = status;
      state.image_dialog.image = image;
    },
    SetStateData(state, { key, val }) {
      state[key] = val;
    },
  },
  actions: {
    GetBlogData(state) {
      GetBlogData().then((res) => {
        state.commit('SetPageData', { key: 'blog_data', val: res.data });
      });
    },
    GetBrandData(state) {
      GetBrandData().then((res) => {
        state.commit('SetPageData', { key: 'brand_data', val: res.data });
      });
    },
    GetCarouselData(state) {
      GetCarouselData().then((res) => {
        state.commit('SetPageData', { key: 'carousel_data', val: res.data });
      });
    },
    GetCommonColumnData(state) {
      GetCommonColumnData().then((res) => {
        state.commit('SetPageData', {
          key: 'common_column_data',
          val: res.data,
        });
      });
    },
    GetProjectData(state) {
      GetProjectData().then((res) => {
        state.commit('SetPageData', { key: 'project_data', val: res.data });
      });
    },
    GetServiceData(state) {
      GetServiceData().then((res) => {
        state.commit('SetPageData', { key: 'service_data', val: res.data });
      });
    },
    GetSeoMetaData(state) {
      GetSeoMetaData().then((res) => {
        state.commit('SetPageData', { key: 'seo_meta_data', val: res.data });
      });
    },
  },
  modules: {},
});
