<script>
import RightArrow from '@/components/svg/RightArrow.vue';
import LeftArrow from '@/components/svg/LeftArrow.vue';
export default {
  name: 'MoreButton',
  data() {
    return {
      hover: false,
    };
  },
  props: {
    text: {
      default: 'Learn More',
      type: String,
    },
    reverse: {
      default: false,
      type: Boolean,
    },
    arrow: {
      default: 'right',
      type: String,
    },
    url: {
      default: '',
      type: String,
    },
  },
  components: {
    RightArrow,
    LeftArrow,
  },
  methods: {
    EmitClick() {
      this.$emit('click');
    },
    HoverAction(val) {
      this.hover = val;
    },
  },
  computed: {
    main_tag() {
      let tag = {
        tag: this.url == '' ? 'button' : 'router-link',
        properties: {
          // px-5
          class:
            'cursor-pointer  font-medium font-clash-display leading-none py-4 px-8 rounded-full flex items-end justify-center leading-none transition-color duration-300',
          on: {
            click: this.EmitClick,
            mouseover: () => {
              this.HoverAction(true);
            },
            mouseout: () => {
              this.HoverAction(false);
            },
          },
        },
      };

      this.reverse ? (tag.properties.class += ' flex-row-reverse') : '';
      if (tag.tag == 'router-link') {
        tag.properties.props = {
          to: this.url,
        };
      }

      return tag;
    },
    arrow_component() {
      let tag = {
        tag: this.arrow == 'left' ? 'LeftArrow' : 'RightArrow',
        properties: {
          class: ' w-7 transform transition-transform duration-300',
        },
      };
      this.hover
        ? this.reverse
          ? (tag.properties.class += ' -translate-x-1')
          : (tag.properties.class += ' translate-x-1')
        : '';
      tag.properties.class += this.reverse ? ' mr-5' : ' ml-5';
      return tag;
    },
  },
  render(createElement) {
    return createElement(this.main_tag.tag, this.main_tag.properties, [
      this.text,
      createElement(this.arrow_component.tag, this.arrow_component.properties),
    ]);
  },
};
</script>
