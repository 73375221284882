<template>
  <footer
    ref="MainContent"
    class="relative z-10 px-5 py-32 overflow-hidden sm:px-10"
  >
    <div
      class="relative z-10 w-full max-w-screen-xl mx-auto"
      data-section-header
    >
      <header class="relative z-10">
        <p class="relative w-full overflow-hidden text-white">
          <span
            data-title-line
            class="title_line opacity-10 absolute sm:top-2 top-1 left-0 right-0 h-[1px] bg-white bg-opacity-70"
          ></span>
          <span data-title data-cursor-title class="block leading-none title_lg"
            >Let's Get In Touch.</span
          >
          <span
            data-title-line
            class="title_line opacity-10 absolute sm:bottom-3 bottom-2 left-0 right-0 h-[1px] bg-white bg-opacity-70"
          ></span>
        </p>
        <span
          data-content-line
          class="content_line w-4 h-[1px] bg-white mt-4 mb-2 block"
        ></span>
        <div data-content class="flex flex-wrap items-start content">
          <p
            class="w-full text-sm font-medium text-white md:w-2/3 sm:text-base"
          >
            請告訴我們您的建議、合作的想法，我們將在收到信件後盡快回覆您，謝謝！
          </p>
          <div class="w-full mt-10 md:w-1/3 md:mt-0">
            <MainButton
              url="/Contact"
              text="Contact Us"
              class="text-white border border-white md:ml-auto border-opacity-80 hover:bg-white hover:text-primary_light"
            />
          </div>
        </div>
      </header>
      <div
        data-content
        data-scroll-x="0"
        data-scroll-y="0"
        class="relative z-10"
      >
        <ContactText class="w-full mt-20 opacity-40" />
        <div class="flex items-center justify-between mt-44">
          <router-link to="/">
            <img
              src="/img/logo_white.webp"
              width="176"
              height="65"
              alt="泳欣事業有限公司"
              class="w-44"
            />
          </router-link>
          <button class="text-center" @click="ScrollToTop">
            <span
              class="flex items-center justify-center w-12 h-12 mx-auto text-xs transition-all duration-300 bg-white rounded-full text-primary_dark hover:bg-primary_dark hover:text-white"
            >
              <TopArrow />
            </span>
            <p class="mt-2 text-sm text-white font-clash-display">Page Top</p>
          </button>
        </div>
        <div class="flex flex-wrap items-stretch w-full mt-16">
          <div class="w-full mb-10 md:w-1/3 sm:w-1/2 sm:mb-0 md:pr-8">
            <p class="text-sm leading-none text-white font-clash-display">
              Office
            </p>
            <i
              class="title_line opacity-10 block mt-2 w-full h-[1px] bg-white bg-opacity-10"
            ></i>
            <p class="mt-4 text-sm font-thin tracking-wider text-white">
              {{ company_address }}
            </p>
            <p
              class="mt-1 text-sm font-thin tracking-wider text-white font-clash-display"
            >
              {{ company_address_en }}
            </p>
            <p
              class="mt-5 text-sm font-thin tracking-wider text-white font-clash-display"
            >
              {{ company_phone }}
            </p>
            <p
              class="mt-5 text-sm font-thin tracking-wider text-white font-clash-display"
            >
              {{ company_email }}
            </p>
          </div>
          <div
            class="flex flex-col w-full h-auto mb-10 md:w-1/3 sm:w-1/2 md:mb-0 md:pr-8"
          >
            <div class="flex-1">
              <p class="text-sm leading-none text-white font-clash-display">
                Follow Us
              </p>
              <i
                class="title_line opacity-10 block mt-2 w-full h-[1px] bg-white bg-opacity-10"
              ></i>
              <div class="flex items-center mt-4">
                <a
                  target="_blank"
                  :href="company_facebook"
                  aria-label="泳欣世界有限公司Facebook"
                  class="flex items-center justify-center w-8 h-8 text-xl transition-all duration-300 bg-white rounded-full text-primary_dark hover:bg-primary_dark hover:text-white"
                >
                  <Facebook />
                </a>
                <a
                  target="_blank"
                  :href="company_instagram"
                  aria-label="泳欣世界有限公司Instagram"
                  class="flex items-center justify-center w-8 h-8 ml-3 text-xl transition-all duration-300 bg-white rounded-full text-primary_dark hover:bg-primary_dark hover:text-white"
                >
                  <Instagram />
                </a>
              </div>
            </div>
            <div class="mt-10">
              <p class="text-sm leading-none text-white font-clash-display">
                Menu
              </p>
              <i
                class="title_line opacity-10 block mt-2 w-full h-[1px] bg-white bg-opacity-10"
              ></i>
              <ol class="flex flex-wrap items-center mt-4">
                <li
                  v-for="(item, item_index) in menu_list"
                  :key="`link_${item_index}`"
                  class="w-1/3 mb-2"
                >
                  <router-link
                    :to="item.link"
                    class="font-medium text-white transition-colors duration-300 font-clash-display hover:text-secondary"
                  >
                    {{ item.title }}
                  </router-link>
                </li>
              </ol>
            </div>
          </div>
          <div class="w-full md:w-1/3">
            <p class="text-sm leading-none text-white font-clash-display">
              Next
            </p>
            <i
              class="title_line opacity-10 block mt-2 w-full h-[1px] bg-white bg-opacity-10"
            ></i>
            <router-link
              :to="next_page.link"
              class="flex items-center justify-between mt-5 text-4xl font-medium text-white font-clash-display md:mt-16"
            >
              {{ next_page.title }}
              <RightArrow class="text-xl" />
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div
      class="absolute top-0 bottom-0 left-0 z-0 w-full h-full px-5 bg-primary_dark sm:px-10"
    >
      <div class="absolute top-0 bottom-0 left-0 right-0 z-0 bg"></div>
      <div
        data-vertical-grow
        class="relative z-10 flex items-stretch w-full h-full max-w-screen-xl mx-auto border-l border-r border-white bg_line border-opacity-10"
      >
        <div
          class="w-1/2 h-full border-r border-white md:w-1/3 opacity-10"
        ></div>
        <div
          class="w-1/2 h-full border-white md:w-1/3 md:border-r opacity-10"
        ></div>
        <div class="hidden w-1/3 h-full md:block opacity-30"></div>
      </div>
    </div>
  </footer>
</template>

<script>
import ContactText from '@/components/svg/ContactText.vue';
import MainButton from '@/components/MainButton';
import TopArrow from './svg/TopArrow.vue';
import Facebook from './svg/Facebook.vue';
import Instagram from './svg/Instagram.vue';
import RightArrow from './svg/RightArrow.vue';
// import { InitGsap, ResetGsap } from '@/gsap/footer.js';
export default {
  name: 'MainFooter',
  components: {
    MainButton,
    ContactText,
    TopArrow,
    Facebook,
    Instagram,
    RightArrow,
  },
  methods: {
    ScrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  },

  computed: {
    next_page() {
      return this.$route.meta.next_page;
    },
    menu_list() {
      return this.$store.state.menu_list;
    },
    common_column_data() {
      return this.$store.state.common_column_data;
    },
    company_facebook() {
      return this.common_column_data.filter(
        (item) => item.column_name == 'company_facebook'
      )[0].content;
    },
    company_instagram() {
      return this.common_column_data.filter(
        (item) => item.column_name == 'company_instagram'
      )[0].content;
    },
    company_phone() {
      return this.common_column_data.filter(
        (item) => item.column_name == 'company_phone'
      )[0].content;
    },
    company_email() {
      return this.common_column_data.filter(
        (item) => item.column_name == 'company_email'
      )[0].content;
    },
    company_address() {
      return this.common_column_data.filter(
        (item) => item.column_name == 'company_address'
      )[0].content;
    },
    company_address_en() {
      return this.common_column_data.filter(
        (item) => item.column_name == 'company_address_en'
      )[0].content;
    },
  },
};
</script>

<style scoped>
.bg {
  background-image: url('@/assets/img/bg.webp');
  background-size: 100px;
  background-repeat: repeat;
}
</style>
