<template>
  <nav
    ref="MainContent"
    class="fixed top-0 z-30 w-full h-screen px-10 -right-full bg-primary_dark"
  >
    <div class="relative z-10 w-full h-full ml-auto sm:w-1/2">
      <div class="py-5 sm:py-10">
        <MainButton
          text="Close"
          @click="CloseMenu"
          class="px-0 ml-auto text-white close_btn hover:text-primary_light"
        />
      </div>
      <div class="">
        <i class="title_line block w-full h-[1px] bg-white opacity-30 mb-2"></i>
        <router-link
          to="/"
          @click.native="CloseMenu"
          class="block py-2 text-xl font-medium text-white transition-colors duration-300 link_btn font-clash-display hover:text-primary_light"
        >
          Homepage
        </router-link>
      </div>
      <div class="mt-5 sm:mt-10">
        <i class="title_line block w-full h-[1px] bg-white opacity-30 mb-2"></i>
        <router-link
          to="/About"
          @click.native="CloseMenu"
          class="block py-2 text-xl font-medium text-white transition-colors duration-300 link_btn font-clash-display hover:text-primary_light"
        >
          About Us
        </router-link>
        <router-link
          to="/Service"
          @click.native="CloseMenu"
          class="block py-2 text-xl font-medium text-white transition-colors duration-300 link_btn font-clash-display hover:text-primary_light"
        >
          Service
        </router-link>
        <router-link
          to="/Case_Overview/all"
          @click.native="CloseMenu"
          class="block py-2 text-xl font-medium text-white transition-colors duration-300 link_btn font-clash-display hover:text-primary_light"
        >
          Project List
        </router-link>
        <router-link
          to="/Blog"
          @click.native="CloseMenu"
          class="block py-2 text-xl font-medium text-white transition-colors duration-300 link_btn font-clash-display hover:text-primary_light"
        >
          Blog
        </router-link>
      </div>
      <div class="mt-5 sm:mt-10">
        <i class="title_line block w-full h-[1px] bg-white opacity-30 mb-2"></i>
        <router-link
          to="/Contact"
          @click.native="CloseMenu"
          class="block py-2 text-xl font-medium text-white transition-colors duration-300 link_btn font-clash-display hover:text-primary_light"
        >
          Contact Us
        </router-link>
      </div>

      <div class="mt-5 sm:mt-10 info">
        <p
          class="leading-none text-white sm:font-thin opacity-40 font-clash-display"
        >
          Office
        </p>
        <i class="w-2 h-[1px] bg-white sm:block hidden mt-3"></i>

        <p class="sm:mt-3">
          <a
            href="mailto:yongxin@yongxin-design.com"
            target="_blank"
            class="text-sm font-thin tracking-wider text-white font-clash-display"
            >{{ company_email }}</a
          >
        </p>
        <p class="mt-2">
          <a
            href="tel:0424719799"
            target="_blank"
            class="text-sm font-thin tracking-wider text-white font-clash-display"
            >{{ company_phone }}</a
          >
        </p>
      </div>

      <div class="mt-5 sm:mt-10 info">
        <p
          class="leading-none text-white sm:font-thin opacity-40 font-clash-display"
        >
          Follow Us
        </p>
        <i class="w-2 h-[1px] bg-white sm:block hidden mt-3"></i>
        <div class="flex items-center mt-2 sm:mt-4">
          <a
            :href="company_facebook"
            target="_blank"
            aria-label="泳欣世界有限公司Facebook"
            class="flex items-center justify-center w-8 h-8 text-xl transition-all duration-300 bg-white rounded-full text-primary_dark hover:bg-primary_dark hover:text-white"
          >
            <Facebook />
          </a>
          <a
            :href="company_instagram"
            target="_blank"
            aria-label="泳欣世界有限公司Instagram"
            class="flex items-center justify-center w-8 h-8 ml-3 text-xl transition-all duration-300 bg-white rounded-full text-primary_dark hover:bg-primary_dark hover:text-white"
          >
            <Instagram />
          </a>
        </div>
      </div>
    </div>
    <div
      class="absolute top-0 bottom-0 flex items-stretch border-l border-r bg_line border-zinc-200 left-10 right-10 opacity-20"
    >
      <div class="w-1/2 h-screen border-r md:w-1/3 border-zinc-200"></div>
    </div>
    <div class="absolute top-0 bottom-0 left-0 right-0 z-0 bg_image"></div>
  </nav>
</template>

<script>
import MainButton from './MainButton.vue';
import Facebook from '@/components/svg/Facebook';
import Instagram from '@/components/svg/Instagram.vue';
import { ResetGsap, OpenMenu, CloseMenu } from '@/gsap/main_menu';
export default {
  components: { MainButton, Facebook, Instagram },
  name: 'MainMenu',
  computed: {
    menu_list() {
      return this.$store.state.menu_list;
    },
    main_menu() {
      return this.$store.state.main_menu;
    },
    common_column_data() {
      return this.$store.state.common_column_data;
    },
    company_facebook() {
      return this.common_column_data.filter(
        (item) => item.column_name == 'company_facebook'
      )[0].content;
    },
    company_instagram() {
      return this.common_column_data.filter(
        (item) => item.column_name == 'company_instagram'
      )[0].content;
    },
    company_phone() {
      return this.common_column_data.filter(
        (item) => item.column_name == 'company_phone'
      )[0].content;
    },
    company_email() {
      return this.common_column_data.filter(
        (item) => item.column_name == 'company_email'
      )[0].content;
    },
  },
  watch: {
    main_menu() {
      this.main_menu ? this.OpenMenu() : this.CloseMenu();
    },
  },
  methods: {
    CloseMenu() {
      this.$store.commit('SetMainMenu', false);
      CloseMenu(this.$refs.MainContent);
    },
    OpenMenu() {
      ResetGsap(this.$refs.MainContent);
      OpenMenu(this.$refs.MainContent);
    },
  },
};
</script>

<style scoped>
.bg_image {
  background-image: url('@/assets/img/bg.webp');
  background-size: 100px;
  background-repeat: repeat;
}
</style>
