<template>
  <section ref="MainContent" class="bg-primary_light bg-opacity-40">
    <div
      class="w-full max-w-screen-xl px-5 mx-auto xl:px-0 sm:px-10 py-36"
      data-section-header
    >
      <header class="mb-10 sm:px-0">
        <p class="relative block w-full overflow-hidden">
          <span
            data-title-line
            class="title_line opacity-10 absolute z-0 sm:top-2 top-1 left-0 right-0 h-[1px] bg-secondary bg-opacity-10"
          ></span>
          <span
            data-title
            data-cursor-title
            class="relative z-10 block leading-none section_title text-secondary"
            >Blog</span
          >
          <span
            data-title-line
            class="title_line opacity-10 absolute z-0 sm:bottom-3 bottom-2 left-0 right-0 h-[1px] bg-secondary bg-opacity-10"
          ></span>
        </p>
        <span
          data-content-line
          class="content_line w-4 h-[1px] bg-secondary mt-4 mb-2 block"
        ></span>
        <div data-content class="flex flex-wrap items-start content">
          <h2 class="w-full text-sm text-secondary md:w-2/3 sm:text-base">
            品牌觀點
          </h2>
          <div class="w-full mt-10 text-right md:w-1/3 md:mt-0">
            <MainButton
              url="/Blog"
              text="View All"
              class="inline-flex w-auto border md:ml-auto border-secondary border-opacity-80 text-secondary hover:bg-secondary hover:text-white"
            />
          </div>
        </div>
      </header>
      <div data-content class="content">
        <div ref="swiper" class="swiper mySwiper">
          <div
            class="flex items-stretch swiper-wrapper whitespace-nowrap md:-mx-10"
          >
            <article
              v-for="(item, item_index) in blog_list"
              :key="`blog_${item_index}`"
              class="overflow-hidden whitespace-normal swiper-slide md:px-10 md:border-r border-primary_dark"
              style="height: auto"
            >
              <router-link to="/" class="flex flex-col h-full">
                <div data-cursor-more class="block overflow-hidden">
                  <img
                    :src="item.thumbnail"
                    width="772"
                    height="434"
                    :alt="item.title"
                    class="block w-full transition-all duration-1000 transform hover:scale-110"
                  />
                </div>
                <span
                  class="w-full block mt-5 mb-3 h-[1px] bg-secondary"
                ></span>
                <header class="flex-1">
                  <h3
                    data-cursor-title
                    class="text-3xl font-bold text-secondary"
                  >
                    {{ item.title }}
                  </h3>
                  <h4 class="mt-2 text-sm text-primary_dark">
                    {{ item.sub_title }}
                  </h4>
                </header>
                <div class="flex items-center mt-5">
                  <time
                    datetime="item.created_at"
                    class="block font-semibold font-clash-display text-secondary"
                  >
                    {{ item.created_at }}
                  </time>
                  <span
                    class="block px-3 py-1 ml-5 text-xs text-white rounded-full"
                    :style="`background-color:${GetColor(item.category)}`"
                    >{{ item.category }}</span
                  >
                </div>
              </router-link>
            </article>
          </div>
        </div>
        <div class="flex items-center mt-28">
          <div class="relative flex-1">
            <span
              class="absolute z-10 top-1/2 left-0 transform -translate-y-1/2 h-[4px] bg-secondary transition-all duration-300"
              :style="`width:${
                ((active_index - blog_list.length) / blog_list.length) * 100
              }%`"
            ></span>
            <span
              class="block w-full h-[1px] bg-primary_light relative z-0"
            ></span>
          </div>
          <div class="flex items-center pl-5 -mx-1 sm:-mx-2">
            <button
              aria-label="上一篇"
              @click="SlideGallery(-1)"
              class="flex items-center justify-center mx-1 text-xs bg-white rounded-full sm:mx-2 sm:w-16 w-14 sm:h-16 h-14 text-primary_light"
            >
              <LeftArrow />
            </button>
            <button
              aria-label="下一篇"
              @click="SlideGallery(1)"
              class="flex items-center justify-center mx-1 text-xs bg-white rounded-full sm:mx-2 sm:w-16 w-14 sm:h-16 h-14 text-primary_light"
            >
              <RightArrow />
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import MainButton from '@/components/MainButton';
import Swiper from 'swiper';
import '@/assets/css/swiper.min.css';
import LeftArrow from '../svg/LeftArrow.vue';
import RightArrow from '../svg/RightArrow.vue';
export default {
  name: 'BlogListSection',
  components: {
    MainButton,
    LeftArrow,
    RightArrow,
  },
  data() {
    return {
      swiper: null,
      // blog_list: [
      //   {
      //     title: '洞察力的重要-頑電動能創業之路',
      //     sub_title: '發現商機提早佈局',
      //     content: '',
      //     category: '粉專行銷',
      //     created_at: '2022/04/18',
      //     cover_image: '/img/blog/blog_list_img_1.png',
      //   },
      //   {
      //     title: '鼎創工程建設公司急需創建官網的理由',
      //     sub_title: '一般工程到政府標案承包商的進化史',
      //     content: '',
      //     category: '網站建置',
      //     created_at: '2022/03/28',
      //     cover_image: '/img/blog/blog_list_img_2.png',
      //   },
      //   {
      //     title: '如何使用Google應用程式密碼讓網站寄信',
      //     sub_title: '網站設定Google SMTP服務教學',
      //     content: '',
      //     category: '網站建置',
      //     created_at: '2021/08/18',
      //     cover_image: '/img/blog/blog_list_img_3.png',
      //   },
      //   {
      //     title: '洞察力的重要-頑電動能創業之路',
      //     sub_title: '發現商機提早佈局',
      //     content: '',
      //     category: '粉專行銷',
      //     created_at: '2022/04/18',
      //     cover_image: '/img/blog/blog_list_img_1.png',
      //   },
      //   {
      //     title: '鼎創工程建設公司急需創建官網的理由',
      //     sub_title: '一般工程到政府標案承包商的進化史',
      //     content: '',
      //     category: '網站建置',
      //     created_at: '2022/03/28',
      //     cover_image: '/img/blog/blog_list_img_2.png',
      //   },
      //   {
      //     title: '如何使用Google應用程式密碼讓網站寄信',
      //     sub_title: '網站設定Google SMTP服務教學',
      //     content: '',
      //     category: '網站建置',
      //     created_at: '2021/08/18',
      //     cover_image: '/img/blog/blog_list_img_3.png',
      //   },
      // ],
    };
  },
  methods: {
    InitSwiper() {
      this.swiper = null;
      this.swiper = new Swiper(this.$refs.swiper, {
        slidesPerView: 'auto',
        spaceBetween: 0,
        loop: true,
        breakpoints: {
          //   768: {
          //     slidesPerView: 3,
          //     spaceBetween: 30,
          //   },
        },
      });
    },
    GetColor(val) {
      return this.blog_category.filter((item) => item.title == val)[0].color;
    },
    SlideGallery(val) {
      if (val == -1) {
        this.swiper.slidePrev();
      } else {
        this.swiper.slideNext();
      }
    },
    InitGsap() {},
  },
  computed: {
    blog_category() {
      return this.$store.state.blog_category;
    },
    active_index() {
      if (this.swiper != null) {
        return this.swiper.activeIndex;
      } else {
        return 0;
      }
    },
    blog_list() {
      return this.$store.state.blog_data.slice(0, 6);
    },
  },
  mounted() {
    this.InitSwiper();
  },
};
</script>

<style scoped>
.swiper-slide {
  width: 66.6666%;
}

@media (max-width: 768px) {
  .swiper-slide {
    width: 100%;
  }
}
</style>
