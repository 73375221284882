<template>
  <section class="relative z-10 -mx-10 overflow-hidden">
    <ol ref="MainContent" class="relative z-10 w-full h-full">
      <li
        data-scroll-fullscreen
        class="relative flex items-center justify-center w-full h-screen px-8 overflow-hidden lg:px-16 card"
        v-for="(item, item_index) in service_list"
        :key="`service_${item_index}`"
      >
        <div class="relative z-10 text-white">
          <img
            :src="item.image"
            width="144"
            height="144"
            :alt="item.sub_title"
            class="mx-auto sm:w-36 w-28"
          />
          <div class="relative z-10 px-10 mx-auto text-center sm:px-20">
            <h4 class="mt-10 text-3xl font-bold">{{ item.sub_title }}</h4>
            <div v-html="item.description" class="mt-5 text-sm"></div>
            <ol class="mt-5 -mx-1">
              <li
                class="inline-block px-2 py-1 mx-1 mb-1 text-xs border border-white rounded-full font-clash-display"
                v-for="(tag, tag_index) in item.tags"
                :key="`tag_${item_index}_${tag_index}`"
              >
                {{ tag }}
              </li>
            </ol>
          </div>

          <MainButton
            :link="item.link"
            class="px-5 py-4 mx-auto mt-10 text-white border rounded-full border-primary_light bg-primary_light"
          />
        </div>
        <div
          class="absolute top-0 bottom-0 left-0 right-0 z-0 bg_image"
          :style="`background-image:url(${item.background_image})`"
        ></div>
      </li>
    </ol>
  </section>
</template>

<script>
import MainButton from '@/components/MainButton.vue';
export default {
  name: 'ServiceSection',
  components: {
    MainButton,
  },
  computed: {
    service_list() {
      return this.$store.state.service_data;
    },
  },
};
</script>
