<template>
  <div
    ref="MainLoading"
    class="fixed top-0 bottom-0 right-0 z-50 flex items-center justify-center w-full -left-full"
  >
    <div data-loading-content class="relative z-10 opacity-0">
      <svg
        width="65"
        height="65"
        data-loading-logo
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_75_2439"
          style="mask-type: alpha"
          maskUnits="userSpaceOnUse"
          x="29"
          y="0"
          width="16"
          height="11"
        >
          <path
            d="M44.5496 0H34.566C31.8961 0 29.7363 2.25989 29.7363 5.04899C29.7363 6.84164 29.7363 8.63429 29.7363 10.4269C34.6709 7.95728 39.6007 5.49238 44.5353 3.02272C44.54 2.01673 44.5496 1.01075 44.5543 0L44.5496 0Z"
            fill="#046A38"
          />
        </mask>
        <mask
          id="mask1_75_2439"
          style="mask-type: alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="40"
          width="10"
          height="26"
        >
          <path
            d="M9.94064 65.2363H4.86305C2.17884 65.2363 0 63.0527 0 60.3542C0 55.3004 0 50.2419 0 45.1882C3.31355 43.529 6.62233 41.8699 9.93587 40.2107C9.93587 48.5542 9.93587 56.8976 9.94064 65.2363Z"
            fill="#046A38"
          />
        </mask>
        <mask
          id="mask2_75_2439"
          style="mask-type: alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="10"
          width="11"
          height="16"
        >
          <path
            d="M0.101562 20.2436C0.101562 18.5082 0.111098 16.768 0.111098 15.0325C0.115866 12.2768 2.31854 10.0503 5.02659 10.0598C6.71912 10.0551 8.41165 10.0455 10.0994 10.0408L10.1137 20.2436L0.101562 25.3355V20.2389V20.2436Z"
            fill="#046A38"
          />
        </mask>
        <mask
          id="mask3_75_2439"
          style="mask-type: alpha"
          maskUnits="userSpaceOnUse"
          x="14"
          y="22"
          width="16"
          height="44"
        >
          <path
            d="M14.9081 27.7338C14.9081 31.0807 14.9081 34.4229 14.9081 37.7698C16.5959 36.9116 18.2836 36.0534 19.9714 35.1952C19.9428 45.2121 19.9094 55.2243 19.8808 65.2412H29.8214V25.2975C29.8214 22.9995 27.4185 21.5024 25.3684 22.5227C21.8785 24.2581 18.3933 25.9936 14.9033 27.729L14.9081 27.7338Z"
            fill="#046A38"
          />
        </mask>
        <mask
          id="mask4_75_2439"
          style="mask-type: alpha"
          maskUnits="userSpaceOnUse"
          x="49"
          y="7"
          width="16"
          height="59"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M64.2637 14.8179C64.2589 16.62 63.2816 18.2744 61.713 19.1231H61.7178L59.7868 20.1672L49.5029 25.3831V19.3069L49.4941 30.3699V65.3171H54.5241C57.1844 65.3171 59.3442 63.1288 59.3442 60.4303L59.3299 20.4245C59.728 20.21 60.1261 19.9942 60.5242 19.7785C60.9223 19.5628 61.3204 19.347 61.7185 19.1325C63.2871 18.2838 64.2645 16.6294 64.2692 14.8273V14.8245C64.2692 12.5131 64.2692 10.2017 64.274 7.89026L64.2692 7.8855L64.2685 7.88588C64.2637 10.1965 64.2637 12.5072 64.2637 14.8179Z"
            fill="#046A38"
          />
        </mask>
        <mask
          id="mask5_75_2439"
          style="mask-type: alpha"
          maskUnits="userSpaceOnUse"
          x="29"
          y="7"
          width="16"
          height="59"
        >
          <path
            d="M29.7305 15.3569V25.3357C31.4278 24.4632 33.1251 23.5955 34.8271 22.723C34.7747 36.8974 34.7222 51.0717 34.6746 65.2509H44.5532V15.3569C44.5532 12.873 44.5532 10.389 44.5532 7.90503C39.6139 10.389 34.6746 12.873 29.7352 15.3569H29.7305Z"
            fill="#046A38"
          />
        </mask>
        <mask
          id="mask6_75_2439"
          style="mask-type: alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="25"
          width="11"
          height="16"
        >
          <path
            d="M10.0842 35.2189L0.143555 40.2107L0.162625 30.3177C3.46187 28.6538 6.76588 26.9899 10.0651 25.3259L10.0794 35.2141L10.0842 35.2189Z"
            fill="#E2E868"
          />
        </mask>
        <mask
          id="mask7_75_2439"
          style="mask-type: alpha"
          maskUnits="userSpaceOnUse"
          x="49"
          y="7"
          width="16"
          height="19"
        >
          <path
            d="M61.713 19.1231C63.2816 18.2744 64.2589 16.62 64.2637 14.8179C64.2637 12.5055 64.2637 10.1932 64.2685 7.88086L52.1728 13.9883C50.5375 14.8131 49.5029 16.5056 49.5029 18.3603V25.3831L59.7868 20.1672C60.4305 19.8192 61.0741 19.4711 61.7178 19.1231H61.713Z"
            fill="#E2E868"
          />
        </mask>

        <!-- LOGO本體 -->
        <g data-logo>
          <!-- 中間上面的點 -->
          <g mask="url(#mask0_75_2439)" class="overflow-hidden">
            <rect data-dark-dot x="30" width="15" height="10" fill="#046A38" />
          </g>

          <!-- 左邊下面的那條 -->
          <g mask="url(#mask1_75_2439)" class="overflow-hidden">
            <rect data-dark-dot y="40" width="10" height="25" fill="#046A38" />
          </g>

          <!-- 左邊上面的點 -->
          <g mask="url(#mask2_75_2439)" class="overflow-hidden">
            <rect data-dark-dot y="10" width="10" height="16" fill="#046A38" />
          </g>

          <!-- 中間左邊的那條 -->
          <g mask="url(#mask3_75_2439)" class="overflow-hidden">
            <rect
              data-dark-line
              x="15"
              y="22"
              width="14"
              height="43"
              fill="#046A38"
            />
          </g>

          <!-- 右下那條 -->
          <g mask="url(#mask4_75_2439)" class="overflow-hidden">
            <rect
              data-dark-line
              x="49"
              y="20"
              width="11"
              height="45"
              fill="#046A38"
            />
          </g>

          <!-- 中間那條 -->
          <g mask="url(#mask5_75_2439)" class="overflow-hidden">
            <rect
              data-dark-line
              x="30"
              y="8"
              width="15"
              height="57"
              fill="#046A38"
            />
          </g>

          <!-- 左邊中間那點 -->
          <g mask="url(#mask6_75_2439)" class="overflow-hidden">
            <rect data-light-dot y="25" width="10" height="15" fill="#E2E868" />
          </g>

          <!-- 右邊上面那點 -->
          <g mask="url(#mask7_75_2439)" class="overflow-hidden">
            <rect
              data-light-dot
              x="48"
              y="8"
              width="17"
              height="17"
              fill="#E2E868"
            />
          </g>
        </g>
      </svg>
    </div>
    <div
      data-loading-bg
      class="absolute top-0 bottom-0 left-0 right-0 bg-white"
    ></div>
  </div>
</template>

<script>
import { Loading } from '@/gsap/loading';
export default {
  name: 'MainLoading',
  data() {
    return {
      loading_effect: null,
    };
  },
  methods: {
    CloseLoading() {
      if (this.loading_effect.loading_time > 0) {
        this.$nextTick(() => {
          console.log('close loading');
          this.loading_effect.close(this.$refs.MainLoading);
          this.$emit('refresh-gsap');
        });
      } else {
        setTimeout(() => {
          this.CloseLoading();
        }, 200);
      }
    },
    OpenLoading() {
      this.$nextTick(() => {
        this.loading_effect.loading(this.$refs.MainLoading);
      });
    },
  },
  watch: {
    main_loading() {
      this.main_loading > 0 ? this.OpenLoading() : this.CloseLoading();
    },
  },
  computed: {
    main_loading() {
      return this.$store.state.main_loading;
    },
  },
  mounted() {
    this.loading_effect = new Loading();
    this.loading_effect.reset(this.$refs.MainLoading);
  },
};
</script>
