<template>
  <section
    ref="MainContent"
    class="relative z-[11] md:pt-56 pt-32 overflow-hidden sm:px-10"
  >
    <div
      class="relative z-10 w-full max-w-screen-xl mx-auto overflow-hidden"
      data-section-header
    >
      <header class="px-5 mb-10 sm:px-0">
        <p>
          <span class="block overflow-hidden">
            <span
              data-title
              data-cursor-title
              class="block text-5xl font-medium first_title font-clash-display md:text-7xl sm:text-6xl text-primary_dark"
              >We Are Your</span
            >
          </span>
          <span class="block overflow-hidden">
            <span
              data-sub-title
              data-cursor-title
              class="block second_title title_lg text-primary_light whitespace-nowrap"
            >
              Brand Partner.
            </span>
          </span>
        </p>
      </header>
      <div data-content>
        <div
          data-cover-box
          class="relative w-full overflow-hidden opacity-0 sm:rounded-tl-3xl sm:rounded-tr-3xl sm:rounded-bl-3xl"
        >
          <div
            data-cover-content
            data-scroll-delay="1.6"
            class="flex-shrink-0 carousel_content md:w-[360px] sm:w-[40%] w-[45%] inline-block absolute -bottom-[1px] -right-[1px] z-10 bg-white main_bg rounded-tl-[40px] transform -skew-x-[30deg]"
          >
            <div
              class="pr-2 md:pl-14 pl-10 md:pt-5 pb-2 sm:pt-2 pt-4 transform skew-x-[30deg] relative z-10"
            >
              <p
                class="font-medium md:text-4xl sm:text-3xl text-secondary font-clash-display"
              >
                0{{ active_index + 1 }}
              </p>
              <p
                class="font-medium leading-none text-secondary font-clash-display"
              >
                {{ carousel_data[active_index].sub_title }} /
                <br class="block sm:hidden" />
                <span class="font-semibold leading-none font-clash-display">{{
                  carousel_data[active_index].title
                }}</span>
              </p>
            </div>
            <div
              class="absolute bg-white main_bg transform skew-x-[30deg] -bottom-0 right-0 w-[20%] h-full z-0"
            ></div>
          </div>
          <div
            data-cover
            data-scroll-in-delay="0.5"
            class="flex items-stretch justify-start w-full carousel_wrapper"
          >
            <div
              class="relative z-10 flex-shrink-0 w-full h-auto cover bg-primary_light"
            ></div>
            <div ref="swiper" class="flex-shrink-0 w-full swiper">
              <ol class="relative z-0 flex items-stretch swiper-wrapper">
                <li
                  class="flex-shrink-0 w-full swiper-slide"
                  v-for="(item, item_index) in carousel_data"
                  :key="`carousel_${item_index}`"
                >
                  <img
                    class="hidden w-full sm:block"
                    width="1280"
                    height="720"
                    :alt="item.title"
                    :src="item.lg_image"
                  />
                  <img
                    class="block w-full sm:hidden"
                    width="492"
                    height="615"
                    :alt="item.title"
                    :src="item.md_image"
                  />
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <hr
        data-content-line
        class="mt-5 border-t-4 bottom_line border-primary_light"
      />
    </div>

    <img
      data-floating
      data-min-x="-50"
      data-max-x="50"
      data-min-y="-70"
      data-max-y="0"
      width="640"
      height="676"
      alt="泳欣事業有限公司"
      class="absolute top-0 right-0 z-0 w-full md:w-1/2"
      src="/img/bg_layerblur_lg.webp"
    />
  </section>
</template>

<script>
import { Autoplay } from 'swiper';
import Swiper from 'swiper';
Swiper.use([Autoplay]);
import '@/assets/css/swiper.min.css';
export default {
  name: 'CarouselSection',
  data() {
    return {
      bg_tl: null,
      swiper: null,
    };
  },
  methods: {
    InitSwiper() {
      this.swiper = null;
      this.swiper = new Swiper(this.$refs.swiper, {
        slidesPerView: 1,
        spaceBetween: 0,
        autoplay: {
          delay: 5000,
        },
        loop: true,
        breakpoints: {},
      });
      // this.swiper.autoplay.stop();
    },
  },
  computed: {
    active_index() {
      if (this.swiper != null) {
        return this.swiper.realIndex;
      } else {
        return 0;
      }
    },
    carousel_data() {
      return this.$store.state.carousel_data;
    },
  },
  mounted() {
    this.InitSwiper();
  },
};
</script>
