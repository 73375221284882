import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      next_page: {
        title: 'About',
        link: '/About',
      },
    },
    component: HomeView,
  },
  {
    path: '/About',
    name: 'about',
    meta: {
      next_page: {
        title: 'Service',
        link: '/Service',
      },
    },
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
  {
    path: '/Service',
    name: 'service',
    meta: {
      next_page: {
        title: 'Project List',
        link: '/Case_Overview/all',
      },
    },
    component: () =>
      import(/* webpackChunkName: "service" */ '../views/ServiceView.vue'),
  },
  {
    path: '/Case_Overview/:type',
    name: 'project_list',
    meta: {
      next_page: {
        title: 'Blog',
        link: '/Blog',
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "project_list" */ '../views/ProjectListView.vue'
      ),
  },
  {
    path: '/case_preview/:id',
    name: 'project_view',
    meta: {
      next_page: {
        title: 'Project List',
        link: '/Case_Overview/all',
      },
    },
    component: () =>
      import(/* webpackChunkName: "project_view" */ '../views/ProjectView.vue'),
  },
  {
    path: '/Blog',
    name: 'blog_list',
    meta: {
      next_page: {
        title: 'Contact',
        link: '/Contact',
      },
    },
    component: () =>
      import(/* webpackChunkName: "blog_list" */ '../views/BlogListView.vue'),
  },
  {
    path: '/Article/:id',
    name: 'blog_view',
    meta: {
      next_page: {
        title: 'Blog',
        link: '/Blog',
      },
    },
    component: () =>
      import(/* webpackChunkName: "blog_view" */ '../views/BlogView.vue'),
  },
  {
    path: '/Contact',
    name: 'contact',
    meta: {
      next_page: {
        title: 'Home',
        link: '/',
      },
    },
    component: () =>
      import(/* webpackChunkName: "contact" */ '../views/ContactView.vue'),
  },
  {
    path: '/error_page',
    name: 'error_page',
    meta: {
      next_page: {
        title: 'Home',
        link: '/',
      },
    },
    component: () =>
      import(/* webpackChunkName: "error_page" */ '../views/ErrorView.vue'),
  },
  {
    path: '/all_case_list',
    name: 'all_case_list',
    meta: {
      next_page: {
        title: 'Home',
        link: '/',
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "all_case_list" */ '../views/CaseListView.vue'
      ),
  },
  {
    path: '/Marketing',
    redirect: '/Service',
  },
  {
    path: '/index.php',
    redirect: '/',
  },
  {
    path: '/index.html',
    redirect: '/',
  },
  {
    path: '*',
    redirect: '/error_page',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      console.log(to.hash);
      return {
        selector: to.hash,
        offset: { x: 0, y: 100 },
      };
    }
    return { x: 0, y: 0 };
  },
});

export default router;
