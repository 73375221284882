<template>
  <div id="MainBg" class="fixed top-0 bottom-0 left-0 right-0 z-0"></div>
</template>

<script>
export default {
  name: 'MainBg',
};
</script>

<style scoped>
#MainBg {
  background-image: url('@/assets/img/bg.webp');
  background-size: 100px;
  background-repeat: repeat;
}
</style>
