<template>
  <article id="Card" class="relative w-full px-10 mb-20 md:w-1/2">
    <router-link
      data-cursor-more
      class="relative block overflow-hidden"
      :to="`/case_preview/${project_data.project_id}`"
    >
      <img
        :src="project_data.thumbnail"
        :alt="project_data.title"
        width="560"
        height="315"
        class="relative z-0 w-full transition-all duration-1000 transform hover:scale-110"
      />
    </router-link>
    <span class="block w-full h-[1px] bg-secondary my-4"></span>
    <router-link
      data-cursor-title
      :to="`/case_preview/${project_data.project_id}`"
    >
      <h4
        class="text-2xl font-semibold transition-colors duration-300 text-secondary font-clash-display sm:text-4xl hover:text-primary_light"
      >
        {{ project_data.title }}
      </h4>
    </router-link>
    <p
      v-if="content_text != ''"
      class="mt-4 text-sm sm:text-base text-primary_dark"
    >
      {{ content_text }}
    </p>
    <ol class="mt-5 -mx-2">
      <li
        class="inline-block px-2 py-1 mx-2 text-xs border rounded-full text-primary_dark border-primary_dark"
        v-for="(item, item_index) in project_data.tags"
        :key="`tag_${item_index}`"
      >
        {{ item }}
      </li>
    </ol>
  </article>
</template>

<script>
export default {
  name: 'ProjectListCard',
  props: {
    project_data: {
      type: Object,
    },
  },
  computed: {
    content_text() {
      let description = this.project_data.description.replaceAll(
        /<[^>]+>/g,
        ''
      );
      return description.length > 80
        ? description.slice(0, 80) + '...'
        : description;
    },
  },
};
</script>

<style scoped>
#Card {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
</style>
