<template>
  <div
    ref="MainContent"
    class="fixed z-0 top-0 left-1/2 transform -translate-x-1/2 w-full max-w-screen-xl xl:px-0 sm:px-10 px-5 opacity-10 mix-blend-screen"
  >
    <div
      data-vertical-grow
      class="bg_line w-full flex items-stretch border-l border-r border-primary_light"
    >
      <div
        class="bg_line md:w-1/3 w-1/2 h-screen border-r border-primary_light"
      ></div>
      <div
        class="bg_line md:w-1/3 w-1/2 h-screen md:border-r border-primary_light"
      ></div>
      <div class="w-1/3 h-screen md:block hidden"></div>
    </div>
  </div>
</template>

<script>
// import { InitGsap, ResetGsap } from '@/gsap/bg_line';
export default {
  name: 'MainBgLine',
  data() {
    return {};
  },
  methods: {
    InitGsap() {
      // ResetGsap(this.$refs.MainContent);
      // InitGsap(this.$refs.MainContent);
    },
  },
};
</script>
